import Error from 'next/error'
import { useRouter } from 'next/router'

export default function Page() {
  const router = useRouter()

  const isListing = router.asPath.includes('listing/')

  if (isListing) {
    return (
      <div className="w-full h-screen flex flex-col justify-center items-center text-center">
        <p className="">404</p>
        <h1 className="text-2xl md:text-4xl">Den valgte bil er ikke længere tilgængelig</h1>
        <p className="text-lg mt-8 max-w-xl">
          Tilmeld dig nyhedsbrevet i footeren for at holde dig opdateret på, hvornår vi får bilen hjem igen.
        </p>
      </div>
    )
  } else {
    return (
      <div className="w-full h-screen flex flex-col justify-center items-center text-center">
        <p className="">404</p>
        <h1 className="text-2xl md:text-4xl">Denne side blev ikke fundet</h1>
      </div>
    )
  }
}
